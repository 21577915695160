/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import PhoneInput from 'react-phone-input-2';

// Ajax Calls
import { retrieveProfile, updateProfile } from 'ajax/profile';
// UI Components
import { SimpleInput, DropdownProfileModules } from 'components/FormHelpers';
import { DefaultButton, PrimaryButton } from 'ui/Button';
import ReactModal from 'ui/ReactModal';
import { ReactConfirmAlert } from 'ui/ReactConfirmAlert';
// State
import { profileState } from 'state';

function ProfileDetails() {
  const profile = useRecoilValue(profileState);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ status: false, mode: '', message: '' });

  // Creating Schema for Password Match Validation
  const DetailsSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required')
  });

  // Capture Password
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      module: '',
      nonce: ''
    },
    validationSchema: DetailsSchema,
    onSubmit: async (formData) => {
      if (formData.mobile.length > 3) {
        // Format mobile number
        formData.mobile = `+${formData.mobile}`;
      } else {
        formData.mobile = '';
      }

      const { data, status } = await updateProfile(formData);
      if (status !== 200) {
        if (data?.type === 'Validation') {
          return data?.data?.map((error) => toast.error(error.message));
        }
        // Modal to refresh the page if data is changed while user is on the edit.
        if (status === 409 && data.type === 'Concurrency') {
          return setConfirmModal({
            status: true,
            mode: 'concurrency',
            message: 'This record has since been updated. Would you like to retrieve the latest copy?'
          });
        }
        if (data?.data) {
          return data?.data?.map((error) => toast.error(error.message));
        }
        return toast.error(data.message);
      }
      setFieldValue('nonce', data.nonce);
      return toast.success('Profile Updated.');
    }
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setValues, isSubmitting } =
    formik;

  const { data: ORIGINAL_DATA } = useQuery(
    'fetchProfile',
    async () => {
      const { data, status } = await retrieveProfile();
      if (status !== 200) return null;
      if (data) {
        let mobileWithoutPlus = '';
        if (data?.mobile?.includes('+')) {
          mobileWithoutPlus = data?.mobile?.replace('+', '');
        }
        const obj = {
          name: data.name,
          email: data.email,
          nonce: data.nonce,
          mobile: mobileWithoutPlus,
          module: data.options?.defaults?.module || ''
        };
        return obj;
      }
      return null;
    },
    { refetchOnWindowFocus: false, onSuccess: (e) => setValues(e) }
  );

  // Check if Data is Changed
  const checkDataChangedHandler = () => {
    if (JSON.stringify(values) === JSON.stringify(ORIGINAL_DATA)) {
      return setIsDataChanged(false);
    }
    if (isDataChanged) return null;
    return setIsDataChanged(true);
  };

  // Discard Changes Handler
  const discardChangesHandler = () => {
    setValues(ORIGINAL_DATA);
    setConfirmModal({ status: false, mode: '', message: '' });
  };

  // Run Effect EveryTime Data Changes
  useEffect(() => {
    checkDataChangedHandler();
  }, [values]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="grid gap-x-8 md:grid-cols-1 max-w-3xl">
            <div>
              <SimpleInput
                field={{ label: 'Name', type: 'text', name: 'name' }}
                value={values.name}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                autoFocus
              />
            </div>
            <div>
              <SimpleInput
                field={{ label: 'Email', type: 'email', name: 'email' }}
                handleChange={handleChange}
                value={values.email}
                errors={errors}
                touched={touched}
              />
            </div>

            <div className="mb-6">
              <p className="mb-2">Mobile</p>
              <div className="relative">
                <PhoneInput
                  country="gb"
                  onlyCountries={['gb', 'ie']}
                  value={values.mobile}
                  onChange={(phone) => setFieldValue('mobile', phone)}
                  enableSearch={false}
                  countryCodeEditable={false}
                  containerClass="!w-full !border-gray-02 !h-[53.80px]"
                  inputClass={`!w-full !border-gray-02 !h-[53.80px] ${
                    errors.mobile && touched.mobile ? '!bg-primary-red/10' : ''
                  }`}
                  inputStyle={{ fontFamily: 'inherit', fontSize: '16px', lineHeight: 'inherit' }}
                  buttonClass="!border !border-gray-02"
                />
                {errors.mobile && touched.mobile && (
                  <p className="text-primary-red absolute top-1/2 transform -translate-y-1/2 right-4">
                    {errors.mobile}
                  </p>
                )}
              </div>
            </div>

            <div>
              <SimpleInput
                field={{ label: 'Role', type: 'text', name: 'role' }}
                readOnly
                handleChange={() => {}}
                value={profile?.role?.display || ''}
                errors={{}}
                touched={{}}
              />
            </div>
            <DropdownProfileModules
              field={{
                name: 'module',
                label: 'Landing Page',
                placeholder: '',
                type: 'text',
                required: false
              }}
              value={values.module}
              setFieldValue={setFieldValue}
              compact={false}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className="flex">
            <div className="mr-2">
              <PrimaryButton type="submit" text="Save" onClick={handleSubmit} disabled={isSubmitting} />
            </div>
            {isDataChanged && (
              <DefaultButton
                text="Discard"
                onClick={() =>
                  setConfirmModal({
                    mode: 'discard',
                    status: true,
                    message: 'Are you sure you want to discard the changes?'
                  })
                }
              />
            )}
          </div>
        </Form>
      </FormikProvider>
      {confirmModal.status && (
        <ReactModal>
          <ReactConfirmAlert
            message={confirmModal.message}
            buttonText="Yes"
            disabled={false}
            onConfirm={
              confirmModal.mode === 'discard'
                ? () => discardChangesHandler()
                : confirmModal.mode === 'concurrency'
                ? () => window.location.reload()
                : null
            }
            onClose={() => setConfirmModal({ status: false, mode: '', message: '' })}
          />
        </ReactModal>
      )}
    </>
  );
}

export default ProfileDetails;
